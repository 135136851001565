import { Text } from '@mantine/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { SPACING_MD } from '@/config/theme';
import { displayTextDate } from '@/helpers/date';
import useWorkshopSessions from '@/hooks/useWorkshopSessions';

const WorkshopDetails = ({ data }) => {
  const workshopSessions = useWorkshopSessions(data);

  const duration_minutes = data.duration_minutes % 60;
  const duration_minutes_str = duration_minutes.toString();

  return (
    <>
      <Text
        sx={{
          marginTop: SPACING_MD,
          fontSize: 18,
          lineHeight: 1.25,
        }}
      >
        {data.where}
        {data?.where_details && ` (${data.where_details})`}
        <br />
        {displayTextDate(data.when_date, true)}
        {workshopSessions.length > 0 && ', '}
        {workshopSessions.join(' // ')}
      </Text>
      <Text
        sx={{
          marginTop: SPACING_MD,
          fontSize: 18,
          lineHeight: 1.25,
        }}
      >
        Durée: {Math.round(data.duration_minutes / 60)}h
        {duration_minutes !== 0 && (
          <>
            {duration_minutes_str.length === 1 ? '0' : ''}
            {duration_minutes}
          </>
        )}
        <br />
        Prix: CHF {data.price_chf}.-
      </Text>
    </>
  );
};

export default WorkshopDetails;
