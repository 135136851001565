import { Button } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { FONT_FAMILY_DEFAULT, SPACING_XS } from '@/config/theme';
import { COLOR_PRIMARY } from '@/config/theme';

const PageButton = ({ title, href, onClick, color, sx, disabled }) => {
  const button = (
    <Button
      onClick={onClick ? onClick : () => {}}
      size='sm'
      variant='outline'
      radius='md'
      sx={{
        color: color ? color : COLOR_PRIMARY,
        borderColor: color ? color : COLOR_PRIMARY,
        fontFamily: FONT_FAMILY_DEFAULT,
        fontWeight: 400,
        lineHeight: 1,
        transition: '.2s',
        marginTop: SPACING_XS,
        ...(sx ? sx : {}),
      }}
      disabled={disabled}
    >
      {title}
    </Button>
  );

  if (href) {
    return <Link href={href}>{button}</Link>;
  } else {
    return button;
  }
};

export default PageButton;
